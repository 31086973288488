@import "../../../../../src/assets/styles/shared.scss";

.dashboardWrapper {
  width: 100%;
  .home-section {
    position: relative;
    background: $defaultcolor;
    height: 100vh;
    left: 260px;
    width: calc(100% - 260px);
    transition: all 0.5s ease;
    margin-top: 69px;
    padding: 30px 0;
    @media (max-width: 1500px) {
      height: 60vh;
      padding: 30px 0 0;
    }
    @media (max-width: 668px) {
      left: 0 !important;
      width: calc(100% - 0px) !important;
      height: auto;
    }
    .home-content {
      padding: 0 0 0 40px;
      .bodyTopsec {
        display: flex;
        justify-content: space-between;
        padding-right: 40px;
        .topSecFlx {
          display: flex;
          align-items: center;
          width: 40%;
          @media (max-width: 668px) {
            width: 100%;
          }
        }
        h2 {
          color: $blackcolor;
          font-size: 28px;
          font-weight: 600;
        }
        .iconSce {
          display: flex;
          .searchSec {
            width: 400px;
            margin-right: 15px;
            // display: flex;
            position: relative;
            @media (max-width: 668px) {
              width: 100%;
              display: flex;
            }
            &:after {
              content: "";
              position: absolute;
              top: 15px;
              right: 0;
              background: url(../../../../assets//images/search-icon.png) 0 0
                no-repeat;
              width: 20px;
              height: 20px;
              margin-right: 17px;
            }
            input {
              border: solid 1px #8e8e8e;
              padding: 12px 20px 15px;
              width: 90%;
              font-size: 16px;
              border-radius: 50px;
              @media (max-width: 668px) {
                width: 100%;
              }
            }
          }
        }
      }
      .sendNotificationBtn {
        border: 2.5px solid $darkgreen;
        outline: none;
        background-color: #fff;
        border-radius: 50px;
        padding: 0 30px;
        color: $darkgreen;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
      }
      .notificationListSec {
        margin-top: 30px;
        .loadingSec {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 50vh;
        }
        .notificationListCard {
          border-top: 2px solid lightgray;
          padding: 20px 10px;
          .notificationCardTopSec {
            display: flex;
            align-items: center;
            justify-content: space-between;
            h1 {
              font-size: 19px;
              font-weight: 600;
            }
            div {
              display: flex;
              align-items: center;
              justify-content: center;
              p {
                margin-left: 5px;
                color: gray;
              }
            }
          }
          p {
            color: gray;
            margin: 10px 0;
          }
          .userImageSec {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            img {
              width: 30px;
              height: 30px;
              border-radius: 100%;
              margin-left: -7px;
              border: 3px solid white;
              background-size: cover;
            }
            span {
              width: 30px;
              height: 30px;
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: lightgray;
              font-size: 10px;
              font-weight: bold;
              margin-left: -7px;
              border: 3px solid white;
            }
          }
        }
      }
    }
  }
}
