@import "../../../../../src/assets/styles/shared.scss";

.popup {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: "";
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9 !important;
  }
  .popup-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 50px 50px;
    z-index: 999;
    width: 500px;
    border-radius: 30px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      font-size: 24px;
      color: $blackcolor;
      font-weight: 600;
      padding-bottom: 40px;
    }
    .form-section {
      width: 100%;
      .input-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 20px;
        label {
          font-size: 16px;
          color: #555555;
          font-weight: 500;
          padding-bottom: 10px;
        }
        input,
        .selectInput,
        textArea {
          width: 100%;
          font-size: 16px;
          outline: none;
          margin-bottom: 5px;
        }
        input,
        textArea {
          padding: 12px 0;
          border: 1px solid lightgray;
          resize: vertical;
        }
      }
      .btnSec {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 30px;
        .cancelBtn {
          border: 2.5px solid $darkgreen;
          outline: none;
          background-color: #fff;
          border-radius: 50px;
          padding: 10px 50px;
          color: $darkgreen;
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
        }
        .sendNotificationBtn {
          border: none;
          outline: none;
          background-color: #fff;
          border-radius: 50px;
          padding: 12.5px 50px;
          background-color: $darkgreen;
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
          color: #fff;
        }
      }
    }
  }
}
