@import "../../../../../src/assets/styles/shared.scss";

.dashboardWrapper {
  width: 100%;
  .home-section {
    position: relative;
    background: $defaultcolor;
    height: 100vh;
    left: 260px;
    width: calc(100% - 260px);
    transition: all 0.5s ease;
    margin-top: 69px;
    padding: 30px 0;
    @media (max-width: 1500px) {
      height: 60vh;
      padding: 30px 0 0;
    }
    @media (max-width: 668px) {
      left: 0 !important;
      width: calc(100% - 0px) !important;
      height: auto;
    }
    .home-content {
      padding: 0 0 0 40px;
      .bodyTopsec {
        display: flex;
        justify-content: space-between;
        padding-right: 40px;
        .topSecFlx {
          display: flex;
          align-items: center;
          width: 40%;
          @media (max-width: 668px) {
            width: 100%;
          }
        }
        h2 {
          color: $blackcolor;
          font-size: 28px;
          font-weight: 600;
        }
        .iconSce {
          display: flex;
          .searchSec {
            width: 400px;
            margin-right: 15px;
            // display: flex;
            position: relative;
            @media (max-width: 668px) {
              width: 100%;
              display: flex;
            }
            &:after {
              content: "";
              position: absolute;
              top: 15px;
              right: 0;
              background: url(../../../../assets//images/search-icon.png) 0 0
                no-repeat;
              width: 20px;
              height: 20px;
              margin-right: 17px;
            }
            input {
              border: solid 1px #8e8e8e;
              padding: 12px 20px 15px;
              width: 90%;
              font-size: 16px;
              @media (max-width: 668px) {
                width: 100%;
              }
            }
          }
          .bxs-pencil {
            background: $yellowbg;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            cursor: pointer;
            margin-right: 15px;
            color: #fff;
          }
          .bx-plus {
            background: $darkgreen;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            cursor: pointer;
            color: $defaultcolor;
          }
        }
      }

      .tableSec {
        padding-right: 40px;
        margin-top: 40px;
        @media (max-width: 668px) {
          padding-right: 20px;
        }
        table {
          border-collapse: collapse;
          border-spacing: 0;
          margin: 0;
          padding: 0;
          width: 100%;
          table-layout: fixed;
        }
        table thead tr th {
          position: relative;
        }
        table thead tr {
          background-color: $defaultcolor;
          border-bottom: 1px solid #848484;
          padding: 0.35em;
          i {
            margin-left: 20%;
            font-size: 30px;
            color: black;
            margin-top: 19px;
            position: absolute;
            top: 0;
            cursor: pointer;
          }
        }

        table tr {
          background-color: $defaultcolor;
          border-bottom: 1px solid #d9d9d9;
          padding: 0.35em;
          position: relative;
          @media (max-width: 668px) {
            padding: 0;
          }
        }

        table th,
        table td {
          padding: 1rem 0.625em;
          text-align: left;
          word-wrap: break-word;
          @media (max-width: 1300px) {
            padding: 1.4rem 0;
          }
          @media (max-width: 668px) {
            padding: 1.4rem 0;
          }
          &:first-child {
            padding: 1rem 3em 1rem 0.625em;
            @media (max-width: 1500px) {
              padding: 1.4rem 0;
            }
          }
          &:last-child {
            padding: 1rem 0 1rem 0.625em;
            width: auto;
            @media (max-width: 668px) {
              padding: 1rem 0 1rem 0;
              width: auto !important;
            }
          }
        }
        table th {
          font-size: 20px;
        }
        table td {
          font-size: 16px;
        }

        @media screen and (max-width: 1000px) {
          table {
            border: 0;
          }

          table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
          }

          table tr {
            display: block;
            margin-bottom: 0.625em;
          }

          table td {
            display: block;
            font-size: 0.8em;
            text-align: right;
            @media (max-width: 668px) {
              display: flex;
              justify-content: space-between;
              height: 50px;
              align-items: center;
              padding: 0;
            }
            &:first-child {
              padding: 0;
            }
            &:last-child {
              padding: 0 0 20px;
            }
          }

          table td::before {
            content: attr(data-label);
            float: left;
            font-weight: bold;
          }

          table td:last-child {
            border-bottom: 0;
          }
        }
        .raBtn {
          background: $yellowbg;
          border-radius: 50px;
          color: $defaultcolor;
          padding: 12px 40px;
          text-decoration: none;
          @media (max-width: 1600px) {
            padding: 12px 30px;
          }
          @media (max-width: 1400px) {
            padding: 12px 20px;
            font-size: 14px;
          }
          @media (max-width: 1200px) {
            padding: 8px 15px;
            font-size: 14px;
          }
        }
        .textEnd {
          text-align: right;
          cursor: pointer;
        }
      }
    }
  }
}

.popup {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: "";
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9 !important;
  }
  .popup-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 50px 50px;
    z-index: 999;
    width: 700px;
    border-radius: 30px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    .bx-x {
      font-size: 40px;
      color: $blackcolor;
      position: absolute;
      right: 40px;
      top: 50px;
      cursor: pointer;
    }
    h2 {
      font-size: 24px;
      color: $blackcolor;
      font-weight: 600;
      padding-bottom: 40px;
    }
    .flexRow {
      display: flex;
      justify-content: space-between;
      .formCol {
        width: 48%;
        .form-group {
          margin-bottom: 20px;
          label {
            font-size: 14px;
            font-weight: 500;
            padding-bottom: 5px;
            display: block;
            text-align: left;
          }
          .form-control {
            @include flex;
            flex-direction: column;
            input {
              padding: 13px 15px 16px;
              border: 1px solid #dfdfdf;
              background-color: #fafafa;
              color: $grey;
              font-size: 14px;
            }
            .error-text {
              font-size: 13px;
              color: red;
              margin-top: 5px;
              text-align: left;
            }
          }
        }
      }
    }
    .btn {
      margin-top: 0;
      padding: 12px 95px;
      font-size: 16px;
      color: $defaultcolor;
      background-color: $darkgreen;
      border: none;
      border-radius: 70px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
      float: right;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &:hover {
        background-color: $defaultheadingcolor;
      }
      .loading-spinner-container {
        position: relative;
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-top: -7px;
        margin-right: 20px;
      }
      .loading-spinner {
        width: 15px;
        height: 15px;
      }
    }
  }
  .popup button {
    margin-top: 10px;
  }
}

.spinnerLoad {
  position: absolute;
  top: 70px;
  left: 0;
  width: 96.9%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

.iconSce {
  @media (max-width: 668px) {
    width: 40px;
    float: right;
  }
  .bxs-pencil {
    background: $yellowbg;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    cursor: pointer;
    margin-right: 15px;
    color: #fff;
    @media (max-width: 668px) {
      margin-right: 0;
      display: inline-flex;
    }
  }
  .bx-plus {
    background: $darkgreen;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    cursor: pointer;
    color: $defaultcolor;
  }
  .bxs-trash {
    background: $defaultheadingcolor;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    cursor: pointer;
    color: $defaultcolor;
    margin-right: 10px;
    @media (max-width: 668px) {
      margin-right: 0;
      display: inline-flex;
    }
  }
}
